
import {
  IonPage,
  IonContent,
  IonList,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonInput,
  IonText,
  IonPopover,
  IonDatetime,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useStore } from "@/store";
import { useRouter } from "vue-router";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import { useI18n } from "vue-i18n";
import { format, parseISO } from "date-fns";
import settingsApp from "@/libs/settings";
import httpApp from "@/libs/http";
import { useLocalStorage } from "@/libs/localStorage";
import VendorNavComponent from "./VendorNavComponent.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import Editor from "@tinymce/tinymce-vue";
export default defineComponent({
  name: "AddProduct",
  setup() {
    const { t, tm, te } = useI18n();
    const router = useRouter();
    const storeX = useStore();
    const { createLoading, openToast, formatNumberLocale } =
      useGlobalFunctions();
    const { getLocalStorageData } = useLocalStorage();
    const templates = ref([]);
    const categories = ref([]);
    const inValiedTemplate = ref(false);
    const selectedTemplate = ref(null);
    const selectedCategory = ref(null);
    const templateQueryTimeout = ref(null);
    const templateQuery = ref(null);
    const templatesData = ref(null);
    const productData = ref(null);
    const templCustomerGroups = ref(null);
    const productFormData = ref({});
    const qtyEditable = ref(false);
    const priceEditable = ref(false);
    const allowBackorders = ref(false);
    const allowGroupPrice = ref(false);
    const allowSpecialPrice = ref(false);
    const priceGroups = ref([]);

    const isConfigurable = ref(false);
    const configurableProducts = ref({});
    const configurableSuperAttributes = ref(null);
    const groupPriceMin = ref(getLocalStorageData("groupPriceMin"));
    const groupPriceMax = ref(getLocalStorageData("groupPriceMax"));
    const editorOptions = ref({
      height: 300,
      width: 250,
      menubar: false,
      plugins: [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime media table paste code help wordcount",
      ],
      toolbar:
        "undo redo | formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | help",
    });

    const inventory = ref({
      backorders: "",
      quantity: "",
    });

    const priceData = ref({
      price: "",
      group_price: [],
      special_price: "",
      special_from_date: "",
      special_to_date: "",
    });

    const configurableInventory = ref({});
    const configurablePrices = ref({});

    const fromDateCalendar = ref();
    const toDateCalendar = ref();

    const addGropuPrice = () => {
      if (priceData.value.group_price.length < priceGroups.value.length) {
        const maxGroupPriceV =
          Number(priceData.value.price) -
          (Number(groupPriceMax.value) / 100) * Number(priceData.value.price);
        priceData.value.group_price.push({
          cust_group: priceGroups.value[0].value,
          price: maxGroupPriceV,
        });
      }
    };

    const removeGropuPrice = (index) => {
      priceData.value.group_price.splice(index, 1);
    };

    const getGroupLabel = (groupOption) => {
      let label;
      priceGroups.value.forEach((group) => {
        if (group.value === groupOption.cust_group) {
          label = group.label;
        }
      });
      return label;
    };

    const formatDate = (value: string) => {
      return format(parseISO(value), "MMM dd yyyy");
    };

    const dateChanged = (ev, type) => {
      if (type == "from_date") {
        priceData.value.special_from_date = formatDate(ev.detail.value);
        fromDateCalendar.value.$el.confirm(true);
      } else {
        priceData.value.special_to_date = formatDate(ev.detail.value);
        toDateCalendar.value.$el.confirm(true);
      }
    };

    const getCategories = () => {
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("categoryByLevel"), {
          store_view: storeX.state.storeCode,
          level: 2,
        })
        .then(async (response: any) => {
          const resp = response;
          if (resp.items) {
            categories.value = resp.items;
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    };

    const templatePage = ref(0);
    const templatePages = ref(1);

    const getTemplates = async (event?: any, emptyProducts = false) => {
      if (emptyProducts) {
        templates.value = [];
        templatePage.value = 0;
        templates.value = [];
      }
      selectedTemplate.value = null;
      templatesData.value = null;
      const loading = await createLoading(t("pleaseWait"));
      templatePage.value = templatePage.value + 1;
      if (templatePage.value > templatePages.value) {
        if (event) {
          event.target.complete();
        }
        return;
      }
      if (!event) {
        loading.present();
      }
      httpApp
        .sendPostRequest(
          settingsApp.getEndpointUrl("sellerGetProductTemplates"),
          {
            store_view: storeX.state.storeCode,
            category_id: selectedCategory.value,
            query: templateQuery.value,
            page: templatePage.value,
            limit: 30,
          }
        )
        .then(async (response: any) => {
          const resp = response;
          if (resp.success) {
            if (resp.templates) {
              templatePages.value = Number(resp.pager.pages);
              templatePage.value = Number(resp.pager.current_page);
              if (Number(resp.pager.pages) == Number(resp.pager.current_page)) {
                if (event) {
                  event.target.complete();
                }
              }
              resp.templates.forEach((item: any) => {
                templates.value.push(item);
              });
            }
            if (event) {
              event.target.complete();
            } else {
              loading.dismiss();
            }
          } else {
            templates.value = [];
            if (event) {
              event.target.complete();
            } else {
              loading.dismiss();
            }
          }
        })
        .catch((error) => {
          console.log(error, "error");
          if (event) {
            event.target.complete();
          } else {
            loading.dismiss();
          }
        });
    };

    const searchByName = (event: any) => {
      clearTimeout(templateQueryTimeout.value);
      templateQueryTimeout.value = setTimeout(() => {
        templateQuery.value = event.target.value;
        getTemplates(null, true);
      }, 500);
    };

    const getProductTemplateData = async (event, templateId) => {
      event.preventDefault();
      selectedTemplate.value = templateId;
      templatesData.value = null;
      productData.value = null;
      priceData.value.group_price = [];
      priceGroups.value.forEach((group) => {
        priceData.value.group_price.push({
          cust_group: group.value,
          price: 0,
        });
      });
      configurableProducts.value = {};
      if (!selectedTemplate.value) {
        inValiedTemplate.value = true;
        return false;
      }
      const loading = await createLoading(t("pleaseWait"));
      loading.present();
      httpApp
        .sendPostRequest(
          settingsApp.getEndpointUrl("sellerGetProductTemplateData"),
          {
            store_view: storeX.state.storeCode,
            template_id: selectedTemplate.value,
          }
        )
        .then(async (response: any) => {
          const resp = response;
          if (resp.success) {
            templatesData.value = resp.template.attributes;
            productData.value = resp.template.product;
            templCustomerGroups.value = resp.template.customer_groups;

            Object.keys(resp.template.attributes).forEach((key) => {
              const attribute = resp.template.attributes[key];
              productFormData.value[attribute.attribute_code] =
                attribute.template_value;
            });

            qtyEditable.value = resp.template.qty_editable;
            priceEditable.value = resp.template.price_editable;
            allowBackorders.value = resp.template.seller_allow_backorders;
            allowGroupPrice.value = resp.template.seller_allow_group_price;
            allowSpecialPrice.value = resp.template.seller_allow_special_price;
            if (
              resp.template.base_attributes.type_id.attr_value ===
              "configurable"
            ) {
              isConfigurable.value = true;
            } else {
              isConfigurable.value = false;
            }
            if (resp.template.simple_products) {
              Object.keys(resp.template.simple_products).forEach((key) => {
                const simpleProduct = resp.template.simple_products[key];

                configurableInventory.value[key] = {
                  backorders: null,
                  quantity: null,
                };
                configurableProducts.value[key] = simpleProduct;
              });
            }
            if (resp.template.super_attributes) {
              Object.keys(resp.template.super_attributes).forEach(
                (attributeCode) => {
                  const superAttribute =
                    resp.template.super_attributes[attributeCode];
                  if (!configurablePrices.value[attributeCode]) {
                    configurablePrices.value[attributeCode] = {};
                  }
                  Object.keys(superAttribute).forEach((sku) => {
                    const superAttributeOption = superAttribute[sku];
                    configurablePrices.value[attributeCode][sku] = {
                      pricing_value:
                        Number(superAttributeOption.pricing_value) * 1,
                      pricing_is_percent:
                        superAttributeOption.pricing_is_percent,
                    };
                  });
                }
              );
            }
            configurableSuperAttributes.value = resp.template.super_attributes;
            loading.dismiss();
          } else {
            loading.dismiss();
            templatesData.value = null;
            productData.value = null;
            templCustomerGroups.value = null;
            qtyEditable.value = false;
            priceEditable.value = false;
            allowBackorders.value = false;
            allowGroupPrice.value = false;
            allowSpecialPrice.value = false;
            configurableProducts.value = {};
            configurableSuperAttributes.value = null;
          }
        })
        .catch((error) => {
          console.log(error, "error");
          loading.dismiss();
        });
    };

    const getCustomerPriceGroups = () => {
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("getCustomerPriceGroups"), {
          store_view: storeX.state.storeCode,
        })
        .then(async (response: any) => {
          const resp = response;
          if (resp.success) {
            priceGroups.value = resp.items;
          } else {
            priceGroups.value = [];
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    };

    const updateGroupPrice = (value, index) => {
      const groupId = priceData.value.group_price[index].cust_group;
      if (groupId == 2 && priceEditable.value) {
        const newPrice =
          Number(value) + (Number(groupPriceMax.value) / 100) * Number(value);
        priceData.value.price = newPrice.toString();
      }
    };

    const saveProductAction = async (event) => {
      event.preventDefault();
      const data = productFormData.value;
      const loading = await createLoading(t("pleaseWait"));

      const shouldValidateGroupPrice =
        allowGroupPrice.value && priceEditable.value ? true : false;

      if (priceData.value.group_price.length == 0 && shouldValidateGroupPrice) {
        openToast(
          t("addproduct.groupPriceRequiredError"),
          "danger",
          false,
          5000
        );
        return false;
      }

      let priceValidated = false;
      if (shouldValidateGroupPrice) {
        priceData.value.group_price.forEach((group) => {
          const groupId = group.cust_group;
          let minEndUserPrice = 0;
          if (groupId == 2) {
            const value = group.price;
            minEndUserPrice =
              Number(value) +
              (Number(groupPriceMin.value) / 100) * Number(value);
          }
          if (Number(priceData.value.price) < minEndUserPrice) {
            openToast(
              t("addproduct.groupPriceError", {
                minPrice: formatNumberLocale(minEndUserPrice),
              }),
              "danger",
              false,
              5000
            );
            return false;
          } else {
            priceValidated = true;
          }
        });
      } else {
        priceValidated = true;
      }

      if (!priceValidated) return false;
      loading.present();
      const sendData = {
        product_data: data,
        inventory_data: inventory.value,
        price_data: priceData.value,
        configurable_inventory_data: configurableInventory.value,
        configurable_prices_data: configurablePrices.value,
        template_id: selectedTemplate.value,
      };
      httpApp
        .sendPostRequest(
          settingsApp.getEndpointUrl("sellerSaveProductData"),
          sendData
        )
        .then(async (response: any) => {
          const resp = response;
          if (resp.success) {
            loading.dismiss();
            const msg = t("addproduct.successAddingProduct");
            const duration = 5000;
            openToast(msg, "success", false, duration);
            router.replace("/vendor/products-list");
          } else {
            loading.dismiss();
            if (te("apiMessages.codes." + resp.error.code)) {
              openToast(t("apiMessages.codes." + resp.error.code), "danger");
            } else {
              openToast(response.error.message, "danger");
            }
          }
        })
        .catch((error) => {
          loading.dismiss();
          console.log(error, "error");
        });
    };

    const ionViewWillEnter = () => {
      getCategories();
      getCustomerPriceGroups();
    };

    return {
      storeX,
      t,
      tm,
      te,
      router,
      ionViewWillEnter,
      categories,
      templates,
      inValiedTemplate,
      selectedTemplate,
      selectedCategory,
      templateQuery,
      getTemplates,
      templatePage,
      templatePages,
      searchByName,
      getProductTemplateData,
      templatesData,
      productData,
      templCustomerGroups,
      qtyEditable,
      priceEditable,
      allowBackorders,
      allowGroupPrice,
      allowSpecialPrice,
      productFormData,
      inventory,
      priceData,
      priceGroups,
      addGropuPrice,
      removeGropuPrice,
      getGroupLabel,
      updateGroupPrice,
      formatDate,
      fromDateCalendar,
      toDateCalendar,
      dateChanged,
      isConfigurable,
      configurableInventory,
      configurableProducts,
      configurableSuperAttributes,
      configurablePrices,
      saveProductAction,
      editorOptions,
    };
  },

  components: {
    IonPage,
    IonContent,
    VendorNavComponent,
    MainHeader,
    MainFooter,
    Editor,
    IonList,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonLabel,
    IonInput,
    IonText,
    IonPopover,
    IonDatetime,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
  },
});
